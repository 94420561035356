.splash {
    margin-top: -296px;
    margin-left: 12px;
    position: fixed;
}

.splashWrapper {
    width: 1470px;
    height: 1046px;
    position: fixed !important;
}

.wrapper {
    display: flex;
}

.wrapper em {
    color: #857E7E;
}

.photo {
    width: 478px;
    height: 345px;
    text-align: center;
    color: #ABABAB;
    overflow: hidden;
    margin: 0 20px 0 45px;
    border: 1px solid #ccc;
}

.address {
    height: 323px; /* line-height * xxx */
    width: 200px;
    margin: -10px 0 0 20px;
}

.container {
    height: 375px;
    width: 225px;
    position: relative;
}

.container p:first-of-type, .container h1 {
    margin-top: 0;
}

.columns {
    height: 350px;
    column-width: 225px;
    column-gap: 30px;
    column-count: 3;
    column-fill: auto;
    overflow: hidden;
}

.controls {
    position: absolute;
    top: 350px;
    width: 100%;
    left: 0;
    z-index: 1000;
}

.controls button {
    font-weight: bolder;
}

.controls .left {
    float: left;
}

.controls .right {
    float: right;
}

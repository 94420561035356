
.App_bodyfloater__tQp96 {
    float: left;
    height: 50%;
    margin-top: -260px;
    width: 100%;
}

.App_body__10BWi {
    clear: both;
    height: 552px;
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
    min-height: 700px
}

.Splash_splash__1NH3p {
    margin-top: -296px;
    margin-left: 12px;
    position: fixed;
}

.Splash_splashWrapper__3VFjs {
    width: 1470px;
    height: 1046px;
    position: fixed !important;
}
.TopMenu_menu-top__3WAHi {
    margin: 20px 0 0 70px;
    height: 22px;
    overflow: hidden;
}

.TopMenu_menu-top__3WAHi a {
    text-transform: uppercase;
    display: block;
    width: 410px;
    float: left;
    font-size: 15px;
    font-weight: normal;
    color: #000;
}

.TopMenu_menu-top__3WAHi a:hover {
    color: #6F9428
}

.TopMenu_menu-top__3WAHi a:last-of-type {
    width: 200px;
}
.SubMenu_menu-left__3pVoB {
    clear: both;
    margin: 0 0 0 50px;
    width: 800px;
    height: 40px;
}

.SubMenu_menu-left__3pVoB a, .SubMenu_menu-left__3pVoB span {
    text-transform: uppercase;
    display: block;
    height: 25px;
    margin-left: 20px;
    overflow: hidden;
    float: left;
    font-weight: normal;
    color: #857E7E;
}

.SubMenu_menu-left__3pVoB a:hover, .SubMenu_menu-left__3pVoB a.SubMenu_active__25tCD {
    color: #000
}

.SubMenu_menu-left__3pVoB.SubMenu_boeken__1bHES {
    margin-left: 460px
}

.About_wrapper__xT9XH {
    display: flex;
}

.About_wrapper__xT9XH em {
    color: #857E7E;
}

.About_photo__3fQv7 {
    width: 478px;
    height: 345px;
    text-align: center;
    color: #ABABAB;
    overflow: hidden;
    margin: 0 20px 0 45px;
    border: 1px solid #ccc;
}

.About_address__1MPHp {
    height: 323px; /* line-height * xxx */
    width: 200px;
    margin: -10px 0 0 20px;
}

.ScrollingDiv_container__2ygcX {
    height: 375px;
    width: 225px;
    position: relative;
}

.ScrollingDiv_container__2ygcX p:first-of-type, .ScrollingDiv_container__2ygcX h1 {
    margin-top: 0;
}

.ScrollingDiv_columns__2ZBUK {
    height: 350px;
    -webkit-column-width: 225px;
       -moz-column-width: 225px;
            column-width: 225px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
       -moz-column-gap: 30px;
            column-gap: 30px;
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    -webkit-column-fill: auto;
       -moz-column-fill: auto;
            column-fill: auto;
    overflow: hidden;
}

.ScrollingDiv_controls__33fmE {
    position: absolute;
    top: 350px;
    width: 100%;
    left: 0;
    z-index: 1000;
}

.ScrollingDiv_controls__33fmE button {
    font-weight: bolder;
}

.ScrollingDiv_controls__33fmE .ScrollingDiv_left__1xA2T {
    float: left;
}

.ScrollingDiv_controls__33fmE .ScrollingDiv_right__2Dgxv {
    float: right;
}

.ProjectList_mainSection__a7bXe {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 390px;
    width: 1100px;
}

.ProjectList_projectItem__eFtiL {
    width: 219px;
    height: 89px;
    margin-right: 44px;
    margin-bottom: 34px;
}

.ProjectList_projectItem__eFtiL a {
    text-decoration: none;
    color: #857E7E;
    text-transform: uppercase;
}

.ProjectList_projectItem__eFtiL a:hover {
    color: #000;
}

.ProjectView_singleProject__2B-Th {
    display: flex;
}

.ProjectView_thumbs__3TMpu {
    width: 260px;
    margin-right: 10px;
}

.ProjectView_thumbs__3TMpu button {
    width: 72px;
    height: 53px;
    border: 1px solid #ccc;
    margin-right: 10px;
    margin-bottom: 35px;
}

.ProjectView_fullImg__iHIJf {
    width: 480px;
    height: 345px;
    overflow: hidden;
    border: 1px solid #ccc;
    margin-right: 35px;
}

.Footer_footer__23fvk {
    clear: both;
    height: 110px;
    width: 100%;
    position: relative;
}

.Footer_footer__23fvk img {
    position: absolute;
    top: 3px;
    z-index: 15;
}

.Footer_footer_logo__gpMR3 {
    position: absolute;
    top: 31px;
    left: 830px
}
/* Global styles */

.whole {
  height: 100%;
  width: 100%;
}

html, body {
  font-family: Arial, Verdana, sans, sans-serif;
  font-size: 11px;
  color: #e3e3e3;
  padding: 0;
  margin: 0;
  height: 100%;
}

a {
  font-family: Arial, Verdana, sans, sans-serif;
  color: #C5C5C5;
  text-decoration: none;
}

a:hover {
}

main {
  height: 390px;
  margin: 15px 15px 0;
}

article {
  color: #000;
  text-align: justify;
  line-height: 14px;
}

article a {
  text-decoration: underline;
  color: #333;
}

article a:hover {
  text-decoration: none;
  color: #666;
}

button {
  background: none;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
}


.bodyfloater {
    float: left;
    height: 50%;
    margin-top: -260px;
    width: 100%;
}

.body {
    clear: both;
    height: 552px;
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
    min-height: 700px
}

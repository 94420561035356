.menu-top {
    margin: 20px 0 0 70px;
    height: 22px;
    overflow: hidden;
}

.menu-top a {
    text-transform: uppercase;
    display: block;
    width: 410px;
    float: left;
    font-size: 15px;
    font-weight: normal;
    color: #000;
}

.menu-top a:hover {
    color: #6F9428
}

.menu-top a:last-of-type {
    width: 200px;
}
.mainSection {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 390px;
    width: 1100px;
}

.projectItem {
    width: 219px;
    height: 89px;
    margin-right: 44px;
    margin-bottom: 34px;
}

.projectItem a {
    text-decoration: none;
    color: #857E7E;
    text-transform: uppercase;
}

.projectItem a:hover {
    color: #000;
}

.menu-left {
    clear: both;
    margin: 0 0 0 50px;
    width: 800px;
    height: 40px;
}

.menu-left a, .menu-left span {
    text-transform: uppercase;
    display: block;
    height: 25px;
    margin-left: 20px;
    overflow: hidden;
    float: left;
    font-weight: normal;
    color: #857E7E;
}

.menu-left a:hover, .menu-left a.active {
    color: #000
}

.menu-left.boeken {
    margin-left: 460px
}
/* Global styles */

.whole {
  height: 100%;
  width: 100%;
}

html, body {
  font-family: Arial, Verdana, sans, sans-serif;
  font-size: 11px;
  color: #e3e3e3;
  padding: 0;
  margin: 0;
  height: 100%;
}

a {
  font-family: Arial, Verdana, sans, sans-serif;
  color: #C5C5C5;
  text-decoration: none;
}

a:hover {
}

main {
  height: 390px;
  margin: 15px 15px 0;
}

article {
  color: #000;
  text-align: justify;
  line-height: 14px;
}

article a {
  text-decoration: underline;
  color: #333;
}

article a:hover {
  text-decoration: none;
  color: #666;
}

button {
  background: none;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
}
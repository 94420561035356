.singleProject {
    display: flex;
}

.thumbs {
    width: 260px;
    margin-right: 10px;
}

.thumbs button {
    width: 72px;
    height: 53px;
    border: 1px solid #ccc;
    margin-right: 10px;
    margin-bottom: 35px;
}

.fullImg {
    width: 480px;
    height: 345px;
    overflow: hidden;
    border: 1px solid #ccc;
    margin-right: 35px;
}

.footer {
    clear: both;
    height: 110px;
    width: 100%;
    position: relative;
}

.footer img {
    position: absolute;
    top: 3px;
    z-index: 15;
}

.footer_logo {
    position: absolute;
    top: 31px;
    left: 830px
}